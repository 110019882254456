import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class BreadcrumbsState {
	private readonly _breadcrumbsParams$ = new BehaviorSubject<string[]>([]);
	private readonly _breadcrumbsQueryParams$ = new BehaviorSubject<Params>({});

	get breadcrumbsParams$(): Observable<string[]> {
		return this._breadcrumbsParams$.asObservable();
	}

	get breadcrumbsQueryParams$(): Observable<Params> {
		return this._breadcrumbsQueryParams$.asObservable();
	}

	setbreadcrumbsParams(params: string[], queryParams: Params = {}) {
		this._breadcrumbsParams$.next(params);
		this._breadcrumbsQueryParams$.next(queryParams);
	}

	getbreadcrumbsParams(): { params: string[]; queryParams: Params } {
		return { params: this._breadcrumbsParams$.getValue(), queryParams: this._breadcrumbsQueryParams$.getValue() };
	}
}
